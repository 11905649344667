/** **************************** Import Types ****************************** */
import { toast } from "react-toastify";
import {
  BLOGCATEGORY_SUCCESS,
  BLOGCATEGORY_FAILURE,
  BLOGCATEGORY_REQUEST,
} from "../types/blogCategoryType";
import { postblogCategory } from "../../api/create";
import { updateBlogCategoryData } from "../../api/updates";
import { getblogcategoryList, getblogCategoryListById } from "../../api/list";
import "react-toastify/dist/ReactToastify.css";

export const blogCategoryRequest = () => ({
  type: BLOGCATEGORY_REQUEST,
});
export const blogCategorySuccess = (users) => ({
  type: BLOGCATEGORY_SUCCESS,
  payload: users,
});
export const blogCategoryFailure = (error) => ({
  type: BLOGCATEGORY_FAILURE,
  payload: error,
});

export const CreateBlogCategory = (data) =>
  async function (dispatch) {
    dispatch(blogCategoryRequest());
    return postblogCategory(data)
      .then((res) => {
        if (!res.code) {
          dispatch(blogCategorySuccess({ responseStatus: "success" }));
          toast.success("Blog category created successfully");
          return "success";
        }
        dispatch(blogCategoryFailure(res.message));
        toast.error(res.message);
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const UpdateblogCategory = (data, userId) =>
  async function (dispatch) {
    dispatch(blogCategoryRequest());
    return updateBlogCategoryData(data, userId)
      .then((res) => {
        if (!res.code && !res.error) {
          dispatch(blogCategorySuccess({ responseStatus: "success" }));
          toast.success("Blog category updated successfully");
          return "success";
        }
        dispatch(blogCategoryFailure(res.error));
        toast.error(res.message || res.error);
        return res.message || res.error;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const GetblogCategoryList = (params) =>
  async function (dispatch) {
    dispatch(blogCategoryRequest());
    return getblogcategoryList({
      ...params,
    })
      .then((res) => {
        if (res?.data?.records) {
          dispatch(blogCategorySuccess({ responseStatus: res }));
          return res;
        }
        dispatch(blogCategoryFailure(res?.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
export const GetblogCategoryById = (params) =>
  async function (dispatch) {
    dispatch(blogCategoryRequest());
    return getblogCategoryListById(params)
      .then((res) => {
        if (res.data.records) {
          dispatch(blogCategorySuccess(res.data.records));
          return res;
        }

        dispatch(blogCategoryFailure(res.message));
        return res.message;
      })
      .catch((err) => console.log("Catch Error:", err));
  };
